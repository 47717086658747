import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <p>
          Diese Website ist vorrübergehend nicht erreichbar.
        </p>
        <p>
          Für Informationen zum Freiburger Institut für Umweltchemie e.V. kontaktieren Sie uns bitte über:
        </p>
        <p>
          Email: info@umweltchemie.org
        </p>
        <p>
          Telefon: +49761286982
        </p>
        <text>Impressum:
        </text>
        <text>Freiburger Institut für Umweltchemie e.V.
        </text>
        <text>Wilhelmstr. 24 a
        </text>
        <text>D-79098 Freiburg i.Br.
        </text>
      </header>
    </div>
  );
}

export default App;
